import request from '@/utils/request'

/**
 *  个人模块 api
 */
// 员工列表
export const getEmployeeListApi = params =>
  request({
    url: '/employee/list',
    method: 'get',
    params
  })
// 员工详情
export const getEmployeeDetailApi = params =>
  request({
    url: `/employee/detail/${params.employeeId}`,
    method: 'get'
  })
// 作品详情
export const getEmployeePortfolioDetailApi = params =>
  request({
    url: `/employee/portfolio/detail/${params.portfolioId}`,
    method: 'get'
  })
// 新增作品
export const employeePortfolioCreateApi = params =>
  request({
    url: `/employee/portfolio/create`,
    method: 'post',
    data: JSON.stringify(params)
  })
// 删除案例
export const employeenPortfolioDeleteApi = params =>
  request({
    url: `/employee/portfolio/delete/${params.employeeId}`,
    method: 'delete'
  })

// 修改作品排序
export const employeePortfolioSortApi = params =>
  request({
    url: `/employee/portfolio/sort`,
    method: 'put',
    data: JSON.stringify(params)
  })

// 修改作品图片排序数
// export const employeePortfolioSortApi = params =>
//   request({
//     url: `/employee/portfolio/modifyPic/sort`,
//     method: 'put',
//     data: JSON.stringify(params)
//   })

/**
 *   我的相关
 */

// 修改信息
export const employeeUpdateApi = params =>
  request({
    url: `/employee/update`,
    method: 'put',
    data: JSON.stringify(params)
  })

export const userModifyInfoApi = params =>
  request({
    url: `/user/modifyInfo`,
    method: 'put',
    data: JSON.stringify(params)
  })
// 员工入驻
export const employeeEnterApi = params =>
  request({
    url: `/employee/enter`,
    method: 'post',
    data: JSON.stringify(params)
  })
// 入驻审核
export const employeeCheckApi = params =>
  request({
    url: `/employee/check`,
    method: 'put',
    data: JSON.stringify(params)
  })
// 邀请入团
export const employeeInviteApi = params =>
  request({
    url: `/employee/invite/${params.teamId}/${params.employeeId}/${params.inviterId}`,
    method: 'post',
    data: JSON.stringify(params)
  })
// 入团邀请列表
export const getEmployeeInviteListApi = params =>
  request({
    url: `/employee/inviteList/${params.employeeId}`,
    method: 'get'
  })
// 邀请入团同意
export const employeeInviteAgreeApi = params =>
  request({
    url: `/employee/invite/agree/${params.teamId}/${params.employeeId}`,
    method: 'put'
  })
// 邀请入团拒绝
export const employeeInviteRejectApi = params =>
  request({
    url: `/employee/invite/reject/${params.teamId}/${params.employeeId}`,
    method: 'put'
  })
// 邀请入团删除
export const employeeInviteDeleteApi = params =>
  request({
    url: `/employee/invite/delete/${params.teamId}/${params.employeeId}`,
    method: 'delete'
  })
