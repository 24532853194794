<template>
  <layoutContainer>
    <div class="enter-container">
      <detailPageCommonHeader
        class="trans_left_header"
        :currentTitle="ruleForm.id ? '团队修改' : '团队入驻'"
        titleInfo="免费入驻，免费接项目"
      ></detailPageCommonHeader>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
        <el-form-item label="团队名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" prop="provinceId">
          <el-select v-model="ruleForm.provinceId" placeholder="请选择活动区域">
            <el-option :label="item.name" :value="item.id" v-for="item in AreaList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务范围" prop="businessList">
          <buttonCheckGroup
            :valueList.sync="ruleForm.businessList"
            :businessList="BusinessList.softwareDevelopmentList"
          />
        </el-form-item>
        <el-form-item label="团队简介" prop="introduction">
          <el-input type="textarea" class="explain" rows="8" v-model="ruleForm.introduction"></el-input>
        </el-form-item>
        <el-form-item label="团队图片" prop="logoPicUrl">
          <uploadTemplate
            @uploadCallBack="uploadCallBack1"
            @deletePic="ruleForm.logoPicUrl = ''"
            :fileList="[ruleForm.logoPicUrl]"
          ></uploadTemplate>
        </el-form-item>
        <el-form-item>
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
      <center>
        <button class="submitButton" @click.stop="submitForm('ruleForm')">
          {{ ruleForm.id ? '立即修改' : '立即创建' }}
        </button>
      </center>
    </div>
  </layoutContainer>
</template>

<script>
import {teamEnterApi, getTeamDetailApi} from '../../api/team'
import {mapState, mapActions} from 'vuex'

const ruleForm = {
  businessList: [],
  introduction: '',
  logoPicUrl: '',
  name: '',
  provinceId: undefined
}

export default {
  computed: {
    ...mapState(['UserInfo', 'AreaList', 'BusinessList'])
  },
  created() {
    const id = this.$route.query.id
    if (id) {
      this.id = id
      this.getTeamDetail()
    }
  },
  data() {
    return {
      id: undefined,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      ruleForm,
      rules: {
        businessList: [{required: 'array', message: '至少选择一个业务', trigger: 'change'}],
        logoPicUrl: {required: true, message: '请上传团队logo图标'},
        provinceId: [{required: true, message: '请选择地区', trigger: 'change'}],
        name: [{required: true, message: '请输入名称', trigger: 'blur'}],
        introduction: [{required: true, message: '请输入简介', trigger: 'blur'}]
      }
    }
  },
  methods: {
    ...mapActions(['ChangeUserInfoActions']),

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.teamEnter()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async teamEnter() {
      await this.getCurrentUserInfo()
      // await this.ChangeUserInfoActions()
      teamEnterApi({...this.ruleForm, employeeId: this.UserInfo.employeeId, userId: this.UserInfo.id})
        .then(res => {
          if (res.data) {
            this.$message(res.data)
            return
          } else {
            this.$message(!this.ruleForm.id ? '团队入驻申请成功，正在审核中，请您耐心等待' : '修改成功')
            // this.$router.go(-1)
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          }
          // this.$router.go(-1)
        })
        .catch(err => {
          console.log('%c 团队入驻结果 err....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
        })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    uploadCallBack1(res) {
      console.log(res.data)
      this.ruleForm.logoPicUrl = res.data
    },
    getTeamDetail() {
      getTeamDetailApi({teamId: this.id})
        .then(res => {
          // console.log('%c getTeamDetailApi....', 'color: red; font-style: italic', JSON.stringify(res.data, null, 2))
          const {teamVo} = JSON.parse(JSON.stringify(res.data))
          this.ruleForm = {...teamVo, businessList: teamVo.businessIdList}
        })
        .catch(err => {})
    }
  }
}
</script>

<style scoped lang="scss">
.trans_left_header {
  position: relative;
  left: -60px;
}
.enter-container {
  /deep/ .el-input__inner {
    border: none;
    border-bottom: 1px solid #f1f1f1;
    border-radius: 0;
  }
  /deep/ .el-form-item__label {
    font-size: 18px !important;
    padding: 0 20px 0 0;
  }
}

.enter-container {
  width: 1200px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 70px;
  padding: 50px 200px 50px 170px;
}
.submitButton {
  margin-top: 30px;
  width: 370px;
  height: 46px;
  border: none;
  outline: none;
  background: #5ad3cd;
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
}
.explain {
  width: 700px;
  height: 200px;
}
</style>
