import request from '@/utils/request'

/**
 *  个人模块 api
 */
// 团队列表
export const getTeamListApi = params =>
  request({
    url: '/team/list',
    method: 'get',
    params
  })
// 团队详情
export const getTeamDetailApi = params =>
  request({
    url: `/team/detail/${params.teamId}`,
    method: 'get'
  })
// 团队信息
export const getTeamInfoApi = params =>
  request({
    url: `/team/getTeamInfo/${params.teamId}`,
    method: 'get'
  })

// 入团申请列表
export const getTeamApplyListApi = params =>
  request({
    url: `/team/applyList/${params.teamId}`,
    method: 'get'
  })

// 申请入团
export const teamApplyApi = params =>
  request({
    url: `/team/apply/${params.teamId}/${params.employeeId}`,
    method: 'post',
    data: JSON.stringify(params)
  })
// 团队入驻
export const teamEnterApi = params =>
  request({
    url: `/team/enter`,
    method: 'post',
    data: JSON.stringify(params)
  })
//  入驻审核
export const teamCheckApi = params =>
  request({
    url: `/team/check`,
    method: 'put',
    data: JSON.stringify(params)
  })
// 申请入团同意
export const teamApplyAgreeApi = params =>
  request({
    url: `/team/apply/agree/${params.teamId}/${params.employeeId}`,
    method: 'put',
    data: JSON.stringify(params)
  })
// 申请入团拒绝
export const teamApplyRejectApi = params =>
  request({
    url: `/team/apply/reject/${params.teamId}/${params.employeeId}`,
    method: 'put',
    data: JSON.stringify(params)
  })
// 修改成员角色
export const teamModifyRoleApi = params =>
  request({
    url: `/team/ModifyRole/${params.teamId}/${params.employeeId}/${params.role}`,
    method: 'put',
    data: JSON.stringify(params)
  })
// 退出团队
export const teamQuitApi = params =>
  request({
    url: `/team/quit/${params.teamId}/${params.employeeId}`,
    method: 'put',
    data: JSON.stringify(params)
  })

// 申请入团删除
export const teamApplyDeleteApi = params =>
  request({
    url: `/team/apply/delete/${params.teamId}/${params.employeeId}`,
    method: 'delete',
    data: JSON.stringify(params)
  })
// 删除成员
export const teamDelMemberApi = params =>
  request({
    url: `/team/delMember/${params.teamId}/${params.employeeId}`,
    method: 'delete',
    data: JSON.stringify(params)
  })
//  解散团队
export const teamDissolveApi = params =>
  request({
    url: `/team/dissolve/${params.teamId}`,
    method: 'delete',
    data: JSON.stringify(params)
  })
