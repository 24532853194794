<template>
  <layoutContainer>
    <div class="box">
      <h3>入驻</h3>
      <el-form class="form" ref="form" :model="form" label-width="200px">
        <el-form-item label="请选择您的身份">
          <el-select v-model="form.type" class="select-box">
            <el-option label="公司" value="1"></el-option>
            <el-option label="团队" value="2"></el-option>
            <el-option label="个人" value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="juzhong">
        <el-button type="primary" @click="nextStep">下一步</el-button>
      </div>
    </div>
  </layoutContainer>
</template>

<script>
import {mapState} from 'vuex'
import {employeeEnterCheckApi, companyEnterCheckApi, teamEnterCheckApi} from '@/api'

export default {
  computed: {
    ...mapState(['UserInfo', 'AreaList', 'BusinessList', 'EducationList', 'EmployeeDomainList'])
  },
  data() {
    return {
      form: {
        type: '1'
      }
    }
  },
  created() {
    this.changeFormType()
  },

  watch: {
    '$route.params': {
      handler() {
        this.changeFormType()
      },
      deep: true
    }
  },
  methods: {
    changeFormType() {
      const {selectType} = this.$route.query
      this.form.type = selectType
    },
    nextStep() {
      switch (this.form.type) {
        case '1':
          companyEnterCheckApi().then(res => {
            console.log('入驻校验结果', res)
            if (['入驻资格校验成功(运营人员)！', '入驻资格校验成功！'].includes(res.data)) {
              this.$router.push('/companyEnter')
            } else {
              this.$message(res.data)
              this.$router.push('/home')
            }
          })
          break
        case '2':
          teamEnterCheckApi().then(res => {
            console.log('入驻校验结果', res)
            if (['入驻资格校验成功(运营人员)！', '入驻资格校验成功！'].includes(res.data)) {
              this.$router.push('/teamEnter')
            } else {
              this.$message(res.data)
              this.$router.push('/home')
            }
          })
          break
        case '3':
          employeeEnterCheckApi().then(res => {
            console.log('入驻校验结果', res)
            if (['入驻资格校验成功(运营人员)！', '入驻资格校验成功！'].includes(res.data)) {
              this.$router.push('/personalEnter')
            } else {
              this.$message(res.data)
              this.$router.push('/home')
            }
          })
          break
      }
      // if (!this.UserInfo?.employeeId && this.form.type != 3) {
      //   this.$message('请先以个人身份入驻成功，才能入驻团队、公司，或加入团队、公司')
      //   return
      // }

      // if (this.form.type == 1 && this.UserInfo.companyId) {
      //   this.$message('您已入驻公司或加入公司 ，请勿重复入驻公司！！！')
      //   return
      // }
      // if (this.form.type == 2 && this.UserInfo.teamId) {
      //   this.$message('您已加入团队或以团队身份入驻成功，请勿重复入驻团队！！！')
      //   return
      // }
      // if (this.form.type == 3 && this.UserInfo.employeeId) {
      //   this.$message('您已以个人身份入驻，请勿重复入驻个人！！！')
      //   return
      // }
      // this.$router.push(enums[this.form.type])
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 542px;
  background: #ffffff;
  padding: 50px 40px;
  h3 {
    padding-left: 10px;
    border-left: 3px solid #5ad3cd;

    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
  }

  .form {
    width: 80%;
    margin-top: 60px;
  }

  button {
    width: 370px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 4px;
    margin-top: 100px;
  }
  /deep/ .el-form-item__label {
    font-size: 20px;
  }
}
.select-box {
  width: 800px;
}
</style>
